<template>
  <div class="orders-table mt-3">
    <v-row
      no-gutters
      class="table-header hidden-sm-and-down text-uppercase mb-2 px-5"
    >
      <v-col
        cols="6"
        md="1"
        class="hidden-sm-and-down d-none d-md-flex justify-center"
      >
        {{ $t("orders.header.orderId") }}
      </v-col>
      <v-col
        cols="2"
        class="hidden-sm-and-down d-none d-md-flex justify-center"
      >
        {{ $t("orders.header.orderDate") }}
      </v-col>
      <v-col
        cols="7"
        md="2"
        class="d-none d-md-flex justify-center text-left text-md-center"
      >
        {{ $t("orders.header.orderDeliveryDate") }}
      </v-col>
      <v-col cols="3" md="2" class="d-none d-md-flex justify-center">
        {{ $t("orders.header.orderStatus") }}
      </v-col>
      <v-col cols="7" md="2" class="d-none d-md-flex justify-center">
        {{ $t("orders.header.orderDelivery") }}
      </v-col>
      <v-col
        cols="1"
        class="d-none d-md-flex justify-center text-left text-md-center"
      >
        {{ $t("orders.header.orderTotal") }}
      </v-col>
      <v-col cols="2">
        &nbsp;
      </v-col>
    </v-row>

    <v-list elevation="0" class="orders-list pa-0 rounded-lg">
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
        class="pa-0"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row
          no-gutters
          class="order-card align-start align-md-center mb-2 pa-5 white"
        >
          <!-- N° ordine -->
          <!-- <v-col cols="6" md="2" class="hidden-sm-and-down"> -->
          <v-col
            cols="6"
            md="1"
            class="order-card-info d-block d-md-flex justify-center px-0"
          >
            <OrderInfoTitle :title="'orders.header.orderId'" />
            <strong class="order-id">#{{ order.orderId }}</strong>
          </v-col>
          <!-- data ordine -->
          <v-col
            cols="6"
            md="2"
            class="order-card-info px-0 d-block d-md-flex justify-center"
          >
            <OrderInfoTitle :title="'orders.header.orderDate'" />
            <span>
              {{
                $dayjs(order.addDate, "YYYY-MM-DDThh:mm:ss A").format(
                  "dddd D MMMM"
                )
              }}
            </span>
          </v-col>
          <!-- data consegna ritiro -->
          <v-col
            cols="6"
            md="2"
            class="order-card-info px-0 d-block d-md-flex justify-center"
          >
            <OrderInfoTitle :title="'orders.header.orderDeliveryDate'" />
            <span class="text-capitalize d-flex flex-column">
              <span class="timeslot">
                {{ $dayjs(order.timeslot.date, "YYYY-MM-DD").format("D MMMM")
                }}<br />
                {{ order.timeslot.name }}
              </span>
            </span>
          </v-col>
          <!-- stato -->
          <v-col
            cols="6"
            md="2"
            class="order-card-info px-0 d-block d-md-flex justify-center"
          >
            <OrderInfoTitle :title="'orders.header.orderStatus'" />
            <OrderStatusBadge v-bind:order="order" />
          </v-col>
          <!-- consegna ritiro -->
          <v-col
            cols="6"
            md="2"
            class="order-card-info px-0 d-block d-md-flex justify-center text-left text-md-center"
          >
            <OrderInfoTitle :title="'orders.header.orderDelivery'" />
            <span class="text-capitalize d-flex flex-column">
              <span class="address">
                {{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".format",
                    order.shippingAddress
                  )
                }}
              </span>
            </span>
          </v-col>
          <!-- totale -->
          <v-col
            cols="6"
            md="1"
            class="order-card-info px-0 d-block d-md-flex justify-center"
          >
            <OrderInfoTitle :title="'orders.header.orderTotal'" />
            <strong> {{ $n(order.grossTotal, "currency") }}</strong>
          </v-col>
          <!-- riordina -->
          <v-col
            cols="12"
            md="2"
            class="actions-order d-flex flex-row justify-end justify-md-center justify-lg-end order-card-info px-0"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <!-- <div class="mb-md-1"> -->
                  <!-- style="width: 100%" -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    @click.stop.prevent="editOrder(order.orderId)"
                    color="secondary"
                    v-if="order.isEditable"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("orders.list.modify") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-1">
                  <!-- <div class="ml-1 ml-md-0"> -->
                  <!-- style="width: 100%" -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    depressed
                    color="secondary"
                    @click.stop.prevent="deleteOrder(order.orderId)"
                    v-if="order.isDeletable"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("orders.list.cancel") }}</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  color="primary"
                  v-if="!order.isEditable"
                  @click.stop.prevent="addAllToCart(order.orderId)"
                  class="ml-1"
                >
                  <span class="hidden-md-and-up white--text"
                    >ORDINA DI NUOVO</span
                  >
                  <v-icon color="white">$cart</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.list.addAllToCart") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped>
.address-image {
  width: 30px;
}
.v-list-item--link::before {
  background-color: #fff;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderInfoTitle from "@/components/orders/OrderInfoTitle";
// import OrderService from "~/service/orderService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  props: {
    orders: { type: Array, required: true },
    ordersAllUsers: { type: Boolean, default: false }
  },
  components: {
    OrderStatusBadge,
    OrderInfoTitle
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    formatDate(order) {
      let day = order.timeslot.date + " " + order.timeslot.beginTime;
      if (this.$dayjs(day).isValid()) {
        let beginFormat = this.$dayjs(day).format("dddd D MMMM");
        return `${beginFormat} | ${order.timeslot.name}`;
      }
      return day;
    },
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    }
  },
  mounted() {}
};
</script>
