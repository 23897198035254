<template>
  <strong
    class="hidden-md-and-up order-info-title"
    v-html="$t(title) + '<br />'"
  >
  </strong>
</template>
<style></style>
<script>
export default {
  name: "OrderDetailQuantity",
  props: {
    title: { type: String, required: true }
  }
};
</script>
